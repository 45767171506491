import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';

import Cursor from "../components/Cursor"
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";

export class NotFound extends Component {

    constructor(props) {
        super(props);
        this.title = null;
        this.description = '';
        this.text = null;
    }

    componentDidMount() {

        const splitTitle = new SplitText(this.title, {type: "chars"});
        TweenMax.staggerFrom(splitTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

    }

    render() {

        var meta = {
            title: 'Страница не найдена.',
            description: 'Запрашиваемой страницы не существует или она была удалена.',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: ''
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
            <div className="wrapper">
                <div className="absoluteContainer container">
                    <div className="absoluteWrapper">
                        <div className="col-row">
                            <div className="col-8-12 col-sm-7-12 hidden-xs">
                            </div>
                            <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                <h1 id="aboutTitle" ref={h1 => this.title = h1}>404</h1>
                            </div>
                        </div>
                        <div className="col-row">
                            <div className="col-1-2 col-sm-7-12 col-xs-12-12"></div>
                        </div>
                    </div>
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}