import { ScrollbarPlugin } from 'smooth-scrollbar';

export default class EdgeEasingPlugin extends ScrollbarPlugin {

    static pluginName = 'edgeEasing';

    _remainMomentum = {
        x: 0,
        y: 0,
    };

    _contentHeight = 0;

    transformDelta(delta) {

        const {
            limit,
            offset,
        } = this.scrollbar;

        const newSize = this.scrollbar.getSize();

        if(this._contentHeight == 0){
            this._contentHeight = this.scrollbar.contentEl.scrollHeight;
        }

        //console.log(this._contentHeight);
        //console.log(this.scrollbar.contentEl.scrollHeight);
        //console.log(newSize.content.height);

        //var scrollContent = document.querySelector('.scroll-content').scrollHeight;
        // newSize.content.height = 8250;
        //
        // const newLimit = {
        //     x: Math.max(newSize.content.width - newSize.container.width, 0),
        //     y: Math.max(newSize.content.height - newSize.container.height, 0),
        // };
        //
        // // metrics
        // const containerBounding = this.scrollbar.containerEl.getBoundingClientRect();
        //
        // const newBounding = {
        //     top: Math.max(containerBounding.top, 0),
        //     right: Math.min(containerBounding.right, window.innerWidth),
        //     bottom: Math.min(containerBounding.bottom, window.innerHeight),
        //     left: Math.max(containerBounding.left, 0),
        // };
        //
        // // assign props
        // this.scrollbar.size = newSize;
        // this.scrollbar.limit = newLimit;
        // this.scrollbar.bounding = newBounding;
        //
        // // update tracks
        // this.scrollbar.track.update();
        //
        // // re-positioning
        // this.scrollbar.setPosition();
    }

    onRender(remainMomentum) {
        Object.assign(this._remainMomentum, remainMomentum);
    }

}