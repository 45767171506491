import React, {Component} from "react";

export class Footer extends Component{

	constructor() {
		super();
	}


	componentDidMount(){


	}

	render() {

		return(
			<footer id="timeLine" className="hoverable">
				<div className="container">
					<div id="yearsCounter">
						<button id="prevYear" className="hoverable">
							<svg width="16px" height="8px" viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="kxm_page_03_03" transform="translate(-591.000000, -700.000000)" fill="#84DCCF">
										<g id="arrows_03" transform="translate(591.000000, 700.000000)">
											<path d="M0,3.994 C0,4.434 0.36,4.794 0.8,4.794 L13.272,4.794 L11.44,6.626 C11.128,6.938 11.128,7.442 11.44,7.754 C11.592,7.914 11.8,7.994 12,7.994 C12.2,7.994 12.408,7.914 12.568,7.762 L15.768,4.562 C15.776,4.554 15.776,4.546 15.784,4.538 C15.848,4.466 15.904,4.394 15.944,4.306 C15.944,4.298 15.944,4.29 15.952,4.282 C15.984,4.202 16,4.114 16,4.034 C16,4.01 16,3.978 15.992,3.954 C15.984,3.89 15.976,3.818 15.952,3.754 C15.944,3.73 15.936,3.698 15.92,3.674 C15.88,3.586 15.832,3.506 15.76,3.434 L12.56,0.234 C12.248,-0.078 11.744,-0.078 11.432,0.234 C11.12,0.546 11.12,1.05 11.432,1.362 L13.272,3.194 L0.8,3.194 C0.36,3.194 0,3.554 0,3.994" id="arrow_left" transform="translate(8.000000, 3.997000) scale(-1, 1) translate(-8.000000, -3.997000) "></path>
										</g>
									</g>
								</g>
							</svg>
						</button>
						<button id="nextYear" className="hoverable">
							<svg width="16px" height="8px" viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="kxm_page_03_03" transform="translate(-673.000000, -700.000000)" fill="#84DCCF">
										<g id="arrows_03" transform="translate(591.000000, 700.000000)">
											<path d="M82,3.994 C82,4.434 82.36,4.794 82.8,4.794 L95.272,4.794 L93.44,6.626 C93.128,6.938 93.128,7.442 93.44,7.754 C93.592,7.914 93.8,7.994 94,7.994 C94.2,7.994 94.408,7.914 94.568,7.762 L97.768,4.562 C97.776,4.554 97.776,4.546 97.784,4.538 C97.848,4.466 97.904,4.394 97.944,4.306 C97.944,4.298 97.944,4.29 97.952,4.282 C97.984,4.202 98,4.114 98,4.034 C98,4.01 98,3.978 97.992,3.954 C97.984,3.89 97.976,3.818 97.952,3.754 C97.944,3.73 97.936,3.698 97.92,3.674 C97.88,3.586 97.832,3.506 97.76,3.434 L94.56,0.234 C94.248,-0.078 93.744,-0.078 93.432,0.234 C93.12,0.546 93.12,1.05 93.432,1.362 L95.272,3.194 L82.8,3.194 C82.36,3.194 82,3.554 82,3.994" id="arrow_right"></path>
										</g>
									</g>
								</g>
							</svg>
						</button>
						<ul></ul>
					</div>
					<div id="goChronology2">
						<div className="container">
							<div className="col-row">
								<div className="col-4-12">
									<div id="dateBegin"></div>
								</div>
								<div className="col-4-12">
									<div id="dateName"></div>
								</div>
								<div className="col-4-12">
									<div id="dateEnd"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="goLine"></div>
			</footer>
		)

	}

}