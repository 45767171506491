import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Tween } from 'react-gsap';
import {gsap} from "gsap";

const MouseFollowStyled = styled.div`
`;

const Cursor = styled.div`
  position: fixed;
  background-color: transparent;
  width: 40px;
  height: 40px;
  left: -30px;
  top: -30px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
`;

class MouseFollowComponent extends PureComponent {

    state = {
        x: window.innerWidth/2,
        y: window.innerHeight/2,
        scaleCircle: 1,
        reload: false
    }

    tween;

    constructor(props) {
        super(props);
        this.mouseMove = this.mouseMove.bind(this);
        this.hoverFunc = this.hoverFunc.bind(this);
        this.unhoverFunc = this.unhoverFunc.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    forceUpdateHandler(){

        this.componentDidMount();

    };

    componentDidMount() {

        window.addEventListener('mousemove', this.mouseMove);

        let hoverables = document.querySelectorAll('a, .hoverable, #modalInterview');
        for (let i=0; i < hoverables.length; i++){
            if(!hoverables[i].classList.contains('unhoverable')){
                hoverables[i].addEventListener('mouseenter', this.hoverFunc);
                hoverables[i].addEventListener('mouseleave', this.unhoverFunc);
            }
        }

    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
    }

    mouseMove({ clientX: x, clientY: y }) {
        this.setState((state) => {
            return {x, y};
            //return state;
        });
    }

    hoverFunc(event){

        let cursorClass = event.target.getAttribute('attr-cursor');
        if(cursorClass != ''){
            let arrowCursor = document.querySelector('.arrowCursor');
            arrowCursor.classList.add(cursorClass);
            arrowCursor.setAttribute('attr-cursor', cursorClass);
        }
        gsap.to("#cursor", .5, {scale:0, opacity: 0});

        if(event.target.classList.contains('zoomed')){
            gsap.to("#cursor_close", .5, {scale: 1, opacity: 1});
        } else {
            gsap.to("#eye", .5, {scale: 1, opacity: 1});
        }

    }

    unhoverFunc(event){

        let arrowCursor = document.querySelector('.arrowCursor'),
            cursorClass = arrowCursor.getAttribute('attr-cursor');

        if(cursorClass != ''){
            arrowCursor.classList.remove(cursorClass);
            arrowCursor.removeAttribute('attr-cursor');
        }
        gsap.to("#eye, #cursor_close", .5, {scale:0, opacity: 0});
        gsap.to("#cursor", .5, {scale: 1, opacity: 1});

    }

    render() {
        const { x, y } = this.state;

        return(
            <MouseFollowStyled className="arrowCursor">
                <Tween to={{x: x, y: y, scale: this.state.scaleCircle}} duration={0.1} ref={ref => this.tween = ref}>
                    <Cursor className={'orange ' + this.props.update ? 'test' : 'test2'}>
                        <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72px" height="72px" viewBox="0 0 60.2 40" xmlSpace="preserve">
                            <g id="Page-1">
                                <g id="kxm_main_page" transform="translate(-899.000000, -617.000000)">
                                    <polygon id="cursor" style={{fill: "none", stroke: "#ff5528"}} points="910.5,618.4 924.9,654.3 930.8,642.7 944.4,656.2 948.2,652.3 934.7,638.8 946.4,632.9"/>
                                </g>
                            </g>
                            <path id="eye" style={{fill: "none", stroke: "#ff5528", opacity: 0}} d="M30.1,5.3c-19.2,0-29.3,16-29.3,16s10.1,16,29.3,16s29.3-16,29.3-16S49.4,5.3,30.1,5.3z M21.1,21.3c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1s-4.1,9.1-9.1,9.1C25.1,30.3,21.1,26.3,21.1,21.3z M27.7,21.3 c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4S27.7,22.6,27.7,21.3z"/>
                            <g id="Page_2">
                                <g id="kxm_page_01" transform="translate(-1044.000000, -337.000000)">
                                    <polygon id="cursor_close" style={{fill: "none", stroke: "#ff5528", opacity: 0}} points="1093.1,341.5 1089.5,337.8 1074.1,353.2 1058.7,337.8 1055,341.5 1070.4,356.9 1055,372.3 1058.7,376 1074.1,360.5 1089.5,376 1093.1,372.3 1077.7,356.9 		"/>
                                </g>
                            </g>
                        </svg>
                    </Cursor>
                </Tween>
            </MouseFollowStyled>
        );
    }
}

export default MouseFollowComponent;