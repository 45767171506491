import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {getGPUTier} from 'detect-gpu';
import MobileDetect from "mobile-detect";

import './App.css';

//Styles
import "./styles/normalize.css"
import "./styles/fonts.css"
import "./styles/main.css"

//Pages & Blocks
import {Home} from './pages/Home';
import {Chapter1} from './pages/Chapter1';
import {Chapter2} from './pages/Chapter2';
import {Chapter3} from './pages/Chapter3';
import {Chapter4} from './pages/Chapter4';
import {Chapter5} from './pages/Chapter5';
import {Chapter6} from './pages/Chapter6';
import {Chapter7} from './pages/Chapter7';
import {NotFound} from './pages/NotFound';
import {Pravi} from "./components/Pravi";
import {Footer} from "./components/Footer";
import Cursor from "./components/Cursor";

class App extends Component {

    constructor() {
        super();
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.GPUTier = getGPUTier().tier;
        this.goodGPU = false;
        if(this.GPUTier == 'GPU_DESKTOP_TIER_3' || this.GPUTier == 'GPU_DESKTOP_TIER_2'){
            this.goodGPU = true;
        }
        this.preventDefault = this.preventDefault.bind(this);
        this.pravi = React.createRef();
        this.cursor = React.createRef();
    }

    preventDefault(e){
        e.preventDefault();
    }

    componentDidUpdate(prevProps) {

        if (this.props.location.pathname !== prevProps.location.pathname) {

            this.pravi.current.activeMenu();

            this.cursor.current.forceUpdateHandler();

        }

    }

    componentDidMount() {

        this.pravi.current.activeMenu();

        document.addEventListener("DOMContentLoaded", function (e) {
            let loadingTrigger = document.getElementById('loadingTrigger');
            loadingTrigger.remove();
        });

    }

    render() {

        const timeout = { enter: 2000, exit: 2000 };

        return (
            <Route render={({location}) => (

                <main>
                    <div id="loadingTrigger"></div>
                    <Pravi ref={this.pravi} />
                    <TransitionGroup component="div" className="fadePage">
                        <CSSTransition
                            key={location.key}
                            timeout={timeout}
                            classNames="fade"
                            onEntering={
                                e => {
                                    document.body.classList.add('loading');
                                    document.addEventListener('wheel', this.preventDefault);
                                }
                            }
                            onEnter={
                                this.pageFadeOut
                            }
                            onExited={
                                this.pageFadeIn,
                                e => {
                                    document.body.classList.remove('loading');
                                    document.removeEventListener('wheel', this.preventDefault);
                                }
                            }
                            onExit={e => {
                                window.scrollTo(0, 0);
                            }}
                        >
                            <Switch location={location}>

                                <Route path='/' exact component={Home} />

                                <Route path='/chapter1' exact component={Chapter1} />

                                <Route path='/chapter2' exact component={Chapter2} />

                                <Route path='/chapter3' exact component={Chapter3} />

                                <Route path='/chapter4' exact component={Chapter4} />

                                <Route path='/chapter5' exact component={Chapter5} />

                                <Route path='/chapter6' exact component={Chapter6} />

                                <Route path='/chapter7' exact component={Chapter7} />

                                <Route path="/404" component={NotFound} status={404} />

                                <Redirect to="/404" />

                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                    <Cursor ref={this.cursor} />
                    <div id="modalInterview"><div className="container"></div></div>
                    <Footer/>
                </main>

            )} />
        );

    }


}

export default App;
